import { fetchApi } from '../api';

const endPoints = {
	signup: '/api/signup',
	resetPassword: '/api/app/reset_password',
	authenticate: '/api/auth/get_tokens',
	userUrl: '/api/res.users',
	voting: '/api/big.sister.app.vote/create',
	roommates: '/api/big.sister.candidate',
	faqs: '/api/big.sister.faq',
	pointsBundle: '/api/point.bundle',
	buyPoints: '/api/point.wallet',
	sponsor: '/api/byte.contact.request',
	contact: '/api/byte.contact.request',
	gallery: '/api/gallery',
	sponsors: '/api/sponsors',
	streamUrl: '/api/stream',
	articleUrl: '/api/big.sister.article',
};

export const sendSponsorship = (payload) =>
	fetchApi(
		endPoints.sponsor,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const sendMessage = (payload) =>
	fetchApi(
		endPoints.contact,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const authenticate = (email, password) =>
	fetchApi(
		endPoints.authenticate,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{ db: 'village', username: email, password: password },
		'POST',
		30000,
	);
export const getGallery = () =>
	fetchApi(
		endPoints.gallery,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);

export const getPointsBundle = (token) =>
	fetchApi(
		endPoints.pointsBundle,
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		{},
		'GET',
		60000,
	);
export const signup = (payload) =>
	fetchApi(
		endPoints.signup,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		payload,
		'POST',
		120000,
	);
export const getRoommates = () =>
	fetchApi(
		endPoints.roommates,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);

export const getSponsors = () =>
	fetchApi(
		endPoints.sponsors,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);
export const getFaqs = () =>
	fetchApi(
		endPoints.faqs,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);
export const resetPassword = (payload) =>
	fetchApi(
		endPoints.resetPassword,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const getUserDetails = (userID, token) =>
	fetchApi(
		endPoints.userUrl + '/' + userID,
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		{},
		'GET',
		30000,
	);
export const getRoommate = (roommateCode) =>
	fetchApi(
		endPoints.roommates + '/' + roommateCode,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);
export const userVote = (payload, token) =>
	fetchApi(
		endPoints.voting,
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const doActivation = (userID, token, payload) =>
	fetchApi(
		endPoints.userUrl + '/' + userID + '/do_activation',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		60000,
	);

export const changePassword = (userID, token, payload) =>
	fetchApi(
		endPoints.userUrl + '/' + userID + '/change_user_password',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const doTransfer = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/do_transfer',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const doNewTransfer = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/do_new_transfer',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		30000,
	);

export const ubaTransfer = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/uba_transfer_complete',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		900000,
	);

export const verifyRave = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/verify_rave',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
		900000,
	);

export const verifyPin = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/verify_pin',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
	);

export const verifyAddress = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/verify_address',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
	);

export const verifyOtp = (walletID, token, payload) =>
	fetchApi(
		endPoints.buyPoints + '/' + walletID + '/verify_otp',
		{ 'Content-Type': 'text/html', accessToken: token, clientId: '12345' },
		payload,
		'POST',
	);

export const getStreamingUrl = () =>
	fetchApi(
		endPoints.streamUrl,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		{},
		'GET',
		30000,
	);
export const getArticles = (payload) =>
	fetchApi(
		endPoints.articleUrl,
		{ 'Content-Type': 'text/html', clientId: '12345' },
		payload,
		'POST',
		30000,
	);
