import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line

import {
	Container as ContainerBase,
	ContentWithVerticalPadding,
	Content2Xl,
} from 'global/misc/Layouts.js';
import { SectionHeading } from 'global/misc/Headings.js';
import { SectionDescription } from 'global/misc/Typography.js';
import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons.js';
import serverIllustrationImageSrc from 'global/images/server-illustration-2.svg';
import strings from 'global/localization/en';

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`lg:text-6xl lg:text-left leading-tight`;
const Description = tw(
	SectionDescription,
)`mt-4 lg:text-6xl text-gray-100 mx-auto lg:mx-0`;
const PrimaryButton = tw(
	PrimaryButtonBase,
)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`;

export default ({
	heading = `${strings.weInnovate}`,
	description = `${strings.toInspire}`,
	primaryButtonText = 'Our Products',
	primaryButtonUrl = '/products',
	imageSrc = serverIllustrationImageSrc,
}) => {
	return (
		<PrimaryBackgroundContainer>
			<Content2Xl>
				<Container>
					<ContentWithVerticalPadding>
						<Row>
							<TextColumn>
								<Heading>{heading}</Heading>
								<Description>{description}</Description>
								<PrimaryButton as='a' href={primaryButtonUrl}>
									{primaryButtonText}
								</PrimaryButton>
							</TextColumn>
							<IllustrationColumn>
								<Image src={imageSrc} />
							</IllustrationColumn>
						</Row>
					</ContentWithVerticalPadding>
				</Container>
			</Content2Xl>
		</PrimaryBackgroundContainer>
	);
};
