import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as SvgDotPatternIcon } from 'global/images/dot-pattern.svg';
import { SectionHeading as HeadingTitle } from 'global/misc/Headings.js';
import strings from 'global/localization/en';

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
	tw`mt-24 md:flex justify-center items-center`,
	props.reversed ? tw`flex-row-reverse` : 'flex-row',
]);
const Image = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 lg:h-64 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-700`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
	SvgDotPatternIcon,
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
	SvgDotPatternIcon,
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
	SvgDotPatternIcon,
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
	SvgDotPatternIcon,
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
	const cards = [
		{
			imageSrc:
				'https://cdn-res.keymedia.com/cms/images/us/039/0248_637153711179863069.jpg',
			subtitle: `${strings.available}`,
			title: `${strings.byteHrms}`,
			description: `${strings.hrmsDesc}`,
			url: '/products/hrms',
		},

		{
			imageSrc: 'https://i.imgur.com/TAhvvA4.png',
			subtitle: `${strings.comingSoon}`,
			title: `${strings.byteSchool}`,
			description: `${strings.byteSchoolDesc}`,
			url: '#',
		},

		{
			imageSrc:
				'https://static1.squarespace.com/static/54e4862de4b0004c775a3d5a/55c4cfc0e4b08dc26b28f253/56bbc4ed7c65e4dfe5dd3ec2/1455147167673/?format=1500w',
			subtitle: `${strings.comingSoon}`,
			title: `${strings.byteVoting}`,
			description: `${strings.byteVoteDesc}`,
			url: '#',
		},
		{
			imageSrc:
				'https://www.phenom.com/sites/default/files/inline-images/Magnifying%20Glass%20Stock%20Photo.jpg',
			subtitle: `${strings.comingSoon}`,
			title: `Byte Found`,
			description: `Byte Found is a mobile application to help find missing item, wherein in people can also post these items there.`,
			url: '#',
		},
	];

	return (
		<Container>
			<SingleColumn>
				<HeadingInfoContainer>
					<HeadingTitle>Our Products</HeadingTitle>
					<HeadingDescription>
						Here are some of our products, some currently being used and other
						coming into the market soon.
					</HeadingDescription>
				</HeadingInfoContainer>

				<Content>
					{cards.map((card, i) => (
						<Card key={i} reversed={i % 2 === 1}>
							<Image imageSrc={card.imageSrc} />
							<Details>
								<Subtitle>{card.subtitle}</Subtitle>
								<Title>{card.title}</Title>
								<Description>{card.description}</Description>
								<Link href={card.url}>See More Details</Link>
							</Details>
						</Card>
					))}
				</Content>
			</SingleColumn>
			<SvgDotPattern1 />
			<SvgDotPattern2 />
			<SvgDotPattern3 />
			<SvgDotPattern4 />
		</Container>
	);
};
