import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { motion } from 'framer-motion';
import {
	SectionHeading,
	Subheading as SubheadingBase,
} from 'global/misc/Headings.js';
import TeamIllustrationSrc from 'global/images/team-illustration-2.svg';
import { ReactComponent as SvgDotPattern } from 'global/images/dot-pattern.svg';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'feather-icons/dist/icons/minus.svg';
import strings from 'global/localization/en';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
	tw`md:w-6/12 mt-16 md:mt-0`,
	props.textOnLeft
		? tw`md:mr-12 lg:mr-16 md:order-first`
		: tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
	props.imageRounded && tw`rounded`,
	props.imageBorder && tw`border`,
	props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
	tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
	SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;

const Question = tw.dt`flex justify-between items-center`;
const QuestionToggleIcon = styled.span`
	${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
	svg {
		${tw`w-4 h-4`}
	}
`;
const Answer = motion.custom(
	tw.dd`pointer-events-none leading-loose text-sm text-gray-600 font-medium`,
);

export default ({
	subheading = 'Our Expertise',
	heading = (
		<>
			Designed & Developed by <span tw='text-primary-500'>Professionals.</span>
		</>
	),
	imageSrc = TeamIllustrationSrc,
	imageRounded = true,
	imageBorder = false,
	imageShadow = false,
	imageDecoratorBlob = false,
	textOnLeft = true,
	steps = null,
	decoratorBlobCss = null,
}) => {
	// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

	const defaultSteps = [
		{
			heading: `${strings.payrollManagement}`,
			description: `${strings.payrollMngtDesc}`,
		},
		{
			heading: `${strings.empInfo}`,
			description: `${strings.empInfoDesc}`,
		},
		{
			heading: `${strings.timeAttend}`,
			description: `${strings.timeAttendMngtDesc}`,
		},
	];

	if (!steps) steps = defaultSteps;
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

	const toggleQuestion = (questionIndex) => {
		if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
		else setActiveQuestionIndex(questionIndex);
	};

	return (
		<Container>
			<TwoColumn>
				<ImageColumn>
					<Image
						src={imageSrc}
						imageBorder={imageBorder}
						imageShadow={imageShadow}
						imageRounded={imageRounded}
					/>
					{imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						<Subheading>{subheading}</Subheading>
						<Heading>{heading}</Heading>
						<Steps>
							{steps.map((step, index) => (
								<Step
									key={index}
									onClick={() => {
										toggleQuestion(index);
									}}
									className='group'>
									<StepNumber>
										{(index + 1).toString().padStart(2, '0')}
									</StepNumber>
									<StepText>
										<Question>
											<StepHeading>{step.heading}</StepHeading>
											<QuestionToggleIcon>
												{activeQuestionIndex === index ? (
													<MinusIcon />
												) : (
													<PlusIcon />
												)}
											</QuestionToggleIcon>
										</Question>
										<Answer
											variants={{
												open: { opacity: 1, height: 'auto', marginTop: '16px' },
												collapsed: { opacity: 0, height: 0, marginTop: '0px' },
											}}
											initial='collapsed'
											animate={
												activeQuestionIndex === index ? 'open' : 'collapsed'
											}
											transition={{
												duration: 0.3,
												ease: [0.04, 0.62, 0.23, 0.98],
											}}>
											{step.description}
										</Answer>
									</StepText>
								</Step>
							))}
						</Steps>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};
