import apiConfig from './config';
import axios from 'axios';
export const exceptionExtractError = (exception) => {
	if (!exception.Errors) return false;
	let error = false;
	const errorKeys = Object.keys(exception.Errors);
	if (errorKeys.length > 0) {
		error = exception.Errors[errorKeys[0]][0].message;
	}
	return error;
};

export const fetchApi = (
	endPoint,
	header = { 'Content-Type': 'text/html' },
	payload = {},
	method = 'POST',
	timeout = 30000,
) => {
	if (method === 'GET') {
		return axios({
			method: method,
			url: apiConfig.url + endPoint,
			headers: header,
			timeout: timeout,
			validateStatus: (status) => {
				return true;
			},
		})
			.then((response) => response['data'])
			.catch((e) => {
				if (e.response && e.response.json) {
					e.response.then((json) => {
						if (json) throw json;
						throw e;
					});
				} else {
					throw e;
				}
			});
	} else if (method === 'PUT') {
		return axios({
			method: method,
			url: apiConfig.url + endPoint,
			headers: header,
			timeout: timeout,
			data: payload,
			validateStatus: (status) => {
				return true;
			},
		})
			.then((response) => response['data'])
			.catch((e) => {
				if (e.response && e.response.json) {
					e.response.then((json) => {
						if (json) throw json;
						throw e;
					});
				} else {
					throw e;
				}
			});
	} else {
		return axios({
			method: method,
			url: apiConfig.url + endPoint,
			headers: header,
			timeout: timeout,
			data: payload,
			validateStatus: (status) => {
				return true;
			},
		})
			.then((response) => response['data'])
			.catch((e) => {
				if (e.response && e.response.json) {
					e.response.then((json) => {
						if (json) throw json;
						throw e;
					});
				} else {
					throw e;
				}
			});
	}
};
