import React, { useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import {
	SectionHeading,
	Subheading as SubheadingBase,
} from 'global/misc/Headings.js';
import { SectionDescription } from 'global/misc/Typography.js';

import defaultCardImage from 'global/images/shield-icon.svg';
import { ReactComponent as SvgDecoratorBlob3 } from 'global/images/svg-decorator-blob-3.svg';
import PerformanceIconImage from 'global/images/management.svg';
import PayrollIconImage from 'global/images/payroll.svg';
import TimeIconImage from 'global/images/working-time.svg';
import LoanIconImage from 'global/images/loan.svg';
import AbsenceIconImage from 'global/images/desk.svg';
import SimpleIconImage from 'global/images/simple-icon.svg';

import strings from 'global/localization/en';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
	${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-xl`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
	${tw`md:w-1/2 lg:w-1/3 max-w-lg `}
`;

const Card = styled.div`
	${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
	.imageContainer {
		${tw`border text-center rounded-full p-5 flex-shrink-0`}
		img {
			${tw`w-12 h-12`}
		}
	}

	.textContainer {
		${tw`sm:ml-4 mt-4 sm:mt-2`}
	}

	.title {
		${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
	}

	.description {
		${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
	}
	.showText {
		${tw`text-primary-500`}
	}
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
	${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
	cards = null,
	heading = '',
	subheading = '',
	description = 'The function of the human resources department involves tracking employee histories, skills, abilities, salaries, and accomplishments. Replacing certain processes with various levels of HRMS systems can distribute information management responsibilities so that the bulk of information gathering is not delegated strictly to HR. By allowing employees to update personal information and perform other tasks, information is kept more accurate and HR professionals are not bogged down.',
}) => {
	/*
	 * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
	 *  1) imageSrc - the image shown at the top of the card
	 *  2) title - the title of the card
	 *  3) description - the description of the card
	 *  If a key for a particular card is not provided, a default value is used
	 */

	const defaultCards = [
		{
			imageSrc: PayrollIconImage,
			title: `${strings.payrollMngt}`,
			description: `${strings.payrollMngtDesc}`,
		},
		{
			imageSrc: PerformanceIconImage,
			title: `${strings.performMngt}`,
			description: `${strings.performMngtDesc}`,
		},
		{
			imageSrc: TimeIconImage,
			title: `${strings.timeAttendMod}`,
			description: `${strings.timeAttendModDesc}`,
		},
		{
			imageSrc: AbsenceIconImage,
			title: `${strings.leaveMngt}`,
			description: `${strings.leaveMngtDesc}`,
		},
		{
			imageSrc: LoanIconImage,
			title: `${strings.loanMngt}`,
			description: `${strings.loanMngtDesc}`,
		},
		{
			imageSrc: SimpleIconImage,
			title: `${strings.recruitment}`,
			description: `${strings.recruitmentDesc}`,
		},
	];

	if (!cards) cards = defaultCards;

	const [visible, setVisible] = useState(7);
	const onLoadMoreClick = () => {
		setVisible((v) => v + 6);
	};

	const shorten = (str, maxLen, separator = ' ') => {
		if (str.length <= maxLen) return str;
		return str.substr(0, str.lastIndexOf(separator, maxLen));
	};

	const executeOnClick = (isExpanded) => {
		return;
	};

	return (
		<Container>
			<ThreeColumnContainer>
				{subheading && <Subheading>{subheading}</Subheading>}
				<Heading>{heading}</Heading>
				{description && <Description>{description}</Description>}
				<VerticalSpacer />
				{cards.map((card, i) => (
					<Column key={i}>
						<Card>
							<span className='imageContainer'>
								<img src={card.imageSrc || defaultCardImage} alt='' />
							</span>
							<span className='textContainer'>
								<span className='title'>{card.title || 'Fully Secure'}</span>
								<p className='description'>
									<ShowMoreText
										lines={4}
										more='Read more'
										less='Show less'
										className='content-css'
										anchorClass='showText'
										onClick={executeOnClick}
										expanded={false}>
										{card.description ||
											'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
									</ShowMoreText>
								</p>
							</span>
						</Card>
					</Column>
				))}
			</ThreeColumnContainer>
			<DecoratorBlob />
		</Container>
	);
};
