import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import promiseMiddleware from 'redux-promise';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(ReduxThunk, promiseMiddleware);

const store = createStore(persistedReducer, middleware);

let persistor = persistStore(store);
const dispatch = store.dispatch;

export { store, dispatch, persistor };
