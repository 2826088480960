import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
	body: '#fff',
	fontColor: '#000',
	transition: 'body 0.25s ease-out',
};

export const darkTheme = {
	body: '#1a1919',
	fontColor: '#eeee',
};

export const GlobalStyles = createGlobalStyle`

	body {

		background-color: ${(props) => props.theme.body};

	}

`;
