import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';

const Container = styled.div`
	${tw`relative -mx-8 pt-20  bg-center bg-cover h-3/4 min-h-144`}
	background-image: url("https://images.unsplash.com/photo-1512626120412-faf41adb4874?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");
`;

// const Container = tw.div`relative pt-32`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center justify-center lg:text-left`;
// const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`text-3xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-2xl font-medium font-mono leading-loose text-gray-100 max-w-lg mx-auto lg:mx-0`;

// const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
// const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
// const WatchVideoButton = styled.button`
// 	${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
// 	.playIcon {
// 		${tw`stroke-1 w-12 h-12`}
// 	}
// 	.playText {
// 		${tw`ml-2 font-medium`}
// 	}
// `;

// const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)

export default () => {
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							<>WE WANT TO </>
							<br />
							<>HEAR FROM YOU</>
						</Heading>
						<Paragraph>
							Fill out our form and we'll get in touch shortly.
						</Paragraph>
					</LeftColumn>
				</TwoColumn>
			</Container>
		</>
	);
};
