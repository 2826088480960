import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import * as api from 'global/services/session/api';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	ListItem,
	List,
	TextField,
} from '@material-ui/core/';
import strings from 'global/localization/en';
import { makeStyles } from '@material-ui/core/styles';

import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons.js';
import BackdropLoader from 'global/loaders/BackDropLoader';

// const ActionButton = styled(PrimaryButtonBase)`
// 	${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
// `;

const GetStartedButton = styled(PrimaryButtonBase)`
	${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

export default ({ plan, btnGradient }) => {
	const classes = useStyles();
	const [fullWidth] = useState(true);
	const [maxWidth] = useState('xs');

	const [open, setOpen] = useState(false);
	const [scroll, setScroll] = useState('paper');
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [note, setNote] = useState('');
	const [validationMsg, setValidationMsg] = useState('');
	const [validErr, setValidErr] = useState(false);

	const handleClickOpen = (scrollType) => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleSuccess = () => {
		setOpen(false);
		setSuccess(false);
	};

	const handleFail = () => {
		setError(false);
	};

	const checkAllFields = () => {
		if (!name) {
			return ['Name is required', false];
		}
		if (!phone) {
			return ['Phone is required', false];
		}
		if (!email) {
			return ['Email is required', false];
		}

		if (!verifyEmail(email)) {
			return ['Please enter a valid Email', false];
		}
		return ['Ok', true];
	};

	const verifyEmail = (email) => {
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return reg.test(email);
	};

	const user = useSelector((state) => state.user.authData);
	let uid = 1;
	if (user) {
		if (user.uid) {
			uid = user.uid;
		}
	}
	const handleClick = () => {
		if (checkAllFields()[0] === 'Ok') {
			setLoader(true);
			let payload = {
				user_id: uid,
				sponsorship_type: plan.toLowerCase(),
				contact_number: phone,
				contact_name: name,
				email: email,
				note: note,
			};
			api
				.sendSponsorship(payload)
				.then((result) => {
					if (result.id) {
						setLoader(false);
						setSuccess(true);
						setEmail('');
						setPhone('');
						setNote('');
						setName('');
					} else {
						setLoader(false);
						setError(true);
					}
				})
				.catch(() => {
					setLoader(false);
					setError(true);
				});
		} else {
			setValidErr(true);
			setValidationMsg(checkAllFields()[0]);
			setTimeout(() => {
				setValidErr(false);
				setValidationMsg('');
			}, 5000);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<div>
			<GetStartedButton css={btnGradient} onClick={handleClickOpen('paper')}>
				{strings.getStarted}
			</GetStartedButton>

			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				{loader ? <BackdropLoader /> : null}
				{success ? (
					<Fragment>
						<DialogTitle>{strings.sponsorSuccess}</DialogTitle>
						<DialogContent>
							<DialogContentText>{strings.detailsSent}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								variant='contained'
								onClick={handleSuccess}
								color='primary'>
								{strings.done}
							</Button>
						</DialogActions>
					</Fragment>
				) : error ? (
					<Fragment>
						<DialogTitle>{strings.sponsorFail}</DialogTitle>
						<DialogContent>
							<DialogContentText>{strings.detailsFail}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleFail} color='primary'>
								{strings.ok}
							</Button>
						</DialogActions>
					</Fragment>
				) : (
					<Fragment>
						<DialogTitle id='scroll-dialog-title'>
							{strings.selectThe} {plan} {strings.pleaseSendContact}
						</DialogTitle>
						<DialogContent dividers={scroll === 'paper'}>
							<DialogContentText
								id='scroll-dialog-description'
								ref={descriptionElementRef}
								tabIndex={-1}>
								<form>
									<List className={classes.root}>
										<ListItem>
											{validErr ? (
												<label>
													<p
														style={{
															color: '#ff0000bf',
															fontSize: '0.7rem',
															border: '1px solid',
															padding: '1rem',
															borderRadius: '10px',
														}}>
														{validationMsg}
													</p>
												</label>
											) : null}
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='name'
												label='Your Name'
												variant='outlined'
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='email'
												label='Your Email'
												variant='outlined'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='phone'
												label='Contact Number'
												variant='outlined'
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												multiline
												rows={4}
												fullWidth
												id='notes'
												label='Extra Notes'
												variant='outlined'
												value={note}
												onChange={(e) => setNote(e.target.value)}
											/>
										</ListItem>
									</List>
								</form>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color='primary' variant='contained'>
								{strings.cancel}
							</Button>
							<Button onClick={handleClick} color='primary' variant='contained'>
								{strings.send}
							</Button>
						</DialogActions>
					</Fragment>
				)}
			</Dialog>
		</div>
	);
};
