import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from 'pages/home/Home';
import NotFound from 'pages/notFound/NotFound';
import Products from './products/Products';
import HRMS from './products/hrms/HRMS';
import Contact from './contact/Contact';
import PrivacyPolicy from './contact/PrivacyPolicy';
import TermsAndConditions from './contact/TermsAndConditions';

export default function Routes({ themeState }) {
	const history = createBrowserHistory();

	return (
		<Router history={history}>
			<Switch>
				<Route exact path='/' component={Home} />
				<Route exact path='/products' component={Products} />
				<Route path='/products/hrms' component={HRMS} />
				<Route path='/privacy-policy' component={PrivacyPolicy} />
				<Route path='/termsandconditions' component={TermsAndConditions} />
				<Route
					path='/contact-us'
					render={() => <Contact themeState={themeState} />}
				/>
				<Route path='' component={NotFound} />
			</Switch>
		</Router>
	);
}
