import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
	SectionHeading,
	Subheading as SubheadingBase,
} from 'global/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'global/misc/Buttons.js';
import TeamIllustrationSrc from 'global/images/team-illustration-2.svg';
import { ReactComponent as SvgDotPattern } from 'global/images/dot-pattern.svg';

import { ReactComponent as FriendlyIcon } from 'feather-icons/dist/icons/heart.svg';
import { ReactComponent as SecurityIcon } from 'feather-icons/dist/icons/shield.svg';

const Container = tw.div`relative `;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
	tw`md:w-6/12 mt-16 md:mt-0`,
	props.textOnLeft
		? tw`md:mr-12 lg:mr-16 md:order-first`
		: tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
	props.imageRounded && tw`rounded`,
	props.imageBorder && tw`border`,
	props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
	tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
	SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
	tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
	props.buttonRounded && tw`rounded-full`,
]);

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const iconRoundedFull = true;
const iconFilled = true;

const FeatureIconContainer = styled.div`
	${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
	${(props) => [
		iconRoundedFull && tw`rounded-full`,
		iconFilled && tw`border-0 bg-primary-500 text-gray-100`,
	]}
  svg {
		${tw`w-5 h-5`}
	}
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

export default ({
	subheading = 'Our Expertise',
	heading = (
		<>
			Designed & Developed by <span tw='text-primary-500'>Professionals.</span>
		</>
	),

	primaryButtonText = 'See Demo',
	primaryButtonUrl = 'https://demo.byteltd.com',
	imageSrc = TeamIllustrationSrc,
	buttonRounded = true,
	imageRounded = true,
	imageBorder = false,
	imageShadow = false,
	imageCss = null,
	imageDecoratorBlob = false,
	imageDecoratorBlobCss = null,
	textOnLeft = true,
}) => {
	// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

	return (
		<Container>
			<TwoColumn>
				<ImageColumn>
					<Image
						css={imageCss}
						src={imageSrc}
						imageBorder={imageBorder}
						imageShadow={imageShadow}
						imageRounded={imageRounded}
					/>
					{imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						<Subheading>{subheading}</Subheading>
						<Heading>{heading}</Heading>
						<Features>
							<Feature>
								<FeatureIconContainer>
									<SecurityIcon />
								</FeatureIconContainer>
								<FeatureText>
									<FeatureHeading>Data Security</FeatureHeading>
									<FeatureDescription>
										Security is of great concern when it comes to choosing a
										human resources management system. The information stored in
										a HRMS is highly sensitive, including proprietary company
										data and volumes of personal information about employees.
									</FeatureDescription>
								</FeatureText>
							</Feature>
						</Features>
						<Features>
							<Feature>
								<FeatureIconContainer>
									<FriendlyIcon />
								</FeatureIconContainer>
								<FeatureText>
									<FeatureHeading>User Friendly</FeatureHeading>
									<FeatureDescription>
										A user-friendly system is one that is easy for novices to
										learn. It is also easy to use. With all the technical
										sophostications of our HRMS we guarantee you that it is
										intuitive, visually engaging, and highly customizable; plus
										a robust IT support.
									</FeatureDescription>
								</FeatureText>
							</Feature>
						</Features>
						<PrimaryButton
							buttonRounded={buttonRounded}
							as='a'
							href={primaryButtonUrl}>
							{primaryButtonText}
						</PrimaryButton>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};
