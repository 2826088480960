import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Hero from 'global/hero/TwoColumnWithPrimaryBackground.js';
import Feature from 'global/features/TwoColWithButton.js';
import Testimonial from 'global/testimonials/TwoColumnWithImageAndProfilePictureReview.js';
import strings from 'global/localization/en';
import WhyUs from 'global/features/WhyUs';
import Competence from 'global/features/Competence';
const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`rounded-4xl`;

export default () => {
	return (
		<div>
			<Hero />
			<Feature
				subheading={<Subheading>{strings.dataScience}</Subheading>}
				heading={
					<>
						{strings.dataPowers}
						<wbr /> <HighlightedText>{strings.thatWeDo}</HighlightedText>
					</>
				}
				description={
					<Description>
						{strings.dataDesc1}
						<br />
						<br />
						{strings.dataDesc2}
					</Description>
				}
				buttonRounded={false}
				textOnLeft={false}
				imageSrc={
					'https://images.unsplash.com/photo-1518186285589-2f7649de83e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
				}
				imageCss={imageCss}
				imageDecoratorBlob={true}
				imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
			/>
			<Feature
				subheading={<Subheading>{strings.ictConsult}</Subheading>}
				heading={
					<>
						{strings.servicesThat}
						<wbr />
						<HighlightedText>{strings.ictNeeds}</HighlightedText>
					</>
				}
				description={
					<Description>
						{strings.ictDesc1}
						<br />
						<br />
						{strings.ictDesc2}
					</Description>
				}
				buttonRounded={false}
				textOnLeft={true}
				imageSrc={
					'https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
				}
				imageCss={imageCss}
				imageDecoratorBlob={true}
				imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
			/>
			<Feature
				subheading={<Subheading>{strings.softwareDev}</Subheading>}
				heading={
					<>
						{strings.softwareIs}
						<wbr /> <HighlightedText>{strings.automation}</HighlightedText>
					</>
				}
				description={
					<Description>
						{strings.softDesc1}
						<br />
						<br />
						{strings.softDesc2}
					</Description>
				}
				buttonRounded={false}
				textOnLeft={false}
				imageSrc={
					'https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
				}
				imageCss={imageCss}
				imageDecoratorBlob={true}
				imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
			/>
			<Feature
				subheading={<Subheading>{strings.networkManagement}</Subheading>}
				heading={
					<>
						{strings.theMostCritical}
						<wbr /> <HighlightedText>{strings.toYourBusiness}</HighlightedText>
					</>
				}
				description={
					<Description>
						{strings.netDesc1}
						<br />
						<br />
						{strings.netDesc2}
					</Description>
				}
				buttonRounded={false}
				textOnLeft={true}
				imageSrc={
					'https://images.unsplash.com/photo-1521542464131-cb30f7398bc6?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
				}
				imageCss={imageCss}
				imageDecoratorBlob={true}
				imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
			/>
			<WhyUs />
			<Competence />
			{/* <MainFeature
				subheading='Reliable'
				heading='Highly Redundant Servers With Backup'
				imageSrc={serverRedundancyIllustrationImageSrc}
				buttonRounded={false}
			/>
			<MainFeature
				subheading='Secure'
				heading='State of the Art Computer Security'
				imageSrc={serverSecureIllustrationImageSrc}
				buttonRounded={false}
				textOnLeft={false}
			/> 
			<Testimonial />
			*/}
			
			
		</div>
	);
};
