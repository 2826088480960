import { DARK_MODE } from '../actions/types';

const initialState = { currentTheme: 'dark' };

export default function (state = initialState, action) {
	switch (action.type) {
		case DARK_MODE:
			return { ...state, currentTheme: action.payload };
		default:
			return state;
	}
}
