const strings = {
	weInnovate: 'WE INNOVATE',
	toInspire: 'TO INSPIRE',
	learnMore: 'Learn More',
	dataScience: 'DATA SCIENCE',
	dataPowers: 'Data really powers everything ',
	thatWeDo: 'that we do.',
	dataDesc1:
		'Data is everywhere. In fact, the amount of digital data that exists is growing at a rapid rate, doubling every two years, and changing the way we live.',
	dataDesc2:
		'We help you take advantage of the data you use and make it more useful.',
	ictConsult: 'ICT CONSULTING',
	servicesThat: 'Services that caters for your organization ',
	ictNeeds: 'ICT needs.',
	ictDesc1:
		'We provide ICT Solutions that meet your specific needs. Our ICT solutions are cost-effective, efficient, secure, and provides a space for scalability as your business/organization grows to match its demands.',
	ictDesc2:
		'Our information and communications technology (ICT) consultancy ensures technology not only meets the requirements of its users but that it is implemented and managed to realise the promised benefits.',
	softwareDev: 'SOFTWARE DEVELOPMENT',
	softwareIs: 'Software is the language of',
	automation: 'automation.',
	softDesc1:
		'The team provides cutting-edge software solutions ranging from Enterprise Resource Planning Software, Mobile App development, Integration systems etc that can be customized to your specific needs.',
	softDesc2:
		'Everything is going to be connected to cloud and data... All of this will be mediated by software.',
	networkManagement: 'NETWORK MANAGEMENT',
	theMostCritical: 'The most critical technology solution you can bring',
	toYourBusiness: 'to your business.',
	netDesc1:
		'We do Installation, configuration, and maintenance of network equipment.',
	netDesc2:
		'Including loading software, unit and network testing to confirm installation of equipment and software solutions and configuration.',
	whyChooseUs: 'WHY CHOOSING US',
	whyUsDesc:
		'Your business and organization depends on your IT systems in this technological era. We’re confident that we can provide all of your network services, computer support, software or it consulting business in order to make sure your business or organization reach its full potential and match international standards.',
	qualityAssurance: 'QUALITY ASSURANCE',
	qualityAssDesc:
		'We have a great team who are extremely innovative and passionate about their work. Thereby you will get original, groundbreaking qualitative services for your business. Our designers and developers are specialized and trained in the latest technologies. From web technologies to mobile applications design, we are experts in all technical fields.',
	infoSecurity: 'INFORMATION SECURITY AND DATA PROTECTION',
	infoSecurityDesc:
		'Information security management for ICT and IT-related services is an important area of concern for every organization. We take the security of our client’s information very seriously. Byte Limited applies proper measures in to ensure confidentiality of information. Our security matches the industry standard.',
	weTalk: 'WE TALK IN YOUR LANGUAGE',
	weTalkDesc:
		'You deserve to have your questions answered in plain English. We want you to understand what we are doing and what the problem is. No geekspeak here!',
	criticalResponse: 'CRITICAL RESPONSE TIME GUARANTEED',
	criticalResponseDesc:
		'Some companies think if your problem doesn’t seem that bad they can wait a few days to get back to you. We get back to you fast so you know when your problem will be resolved. Our contracts spell out our response time. And when you’re dead in the water, we respond – FAST.',
	values: 'VALUES',
	weAlwaysAbide: 'We Always Abide by Our ',
	corePrinciples: 'Core Principles.',
	valuesDesc:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	professionalism: 'Professionalism',
	profDesc:
		'We make sure we attain maximum professionalism in our work, by hiring very professional staffs just to work with you.',
	affordable: 'Affordable',
	affordableDesc:
		'We promise to offer you the best rate we can - at par with the industry standard.',
	//FOOTER
	copyright: ' Copyright ',
	allRightReserved: ', Byte Limited. All Rights Reserved.',
	// NAV
	home: 'Home',
	ourProducts: 'Our Products',
	contactUs: 'Contact Us',
	// PRODUCT PAGE
	available: 'Available',
	comingSoon: 'Coming Soon!',
	byteHrms: 'Byte HR++',
	hrmsDesc:
		'HR work is more complex and involved today than it ever has been. There’s so much that goes into the management of employee information, which is used for everything from recruiting and hiring to training, evaluations, and so much more. The importance and manpower behind these tasks make it critical for human resources professionals to have HR management software for more efficient management of HR information.',
	byteSchool: 'Byte School',
	byteSchoolDesc:
		'Byte School Cloud is a powerful school management software that comes with an incredible set of customizable features to assist with your school administration tasks and complex processes. Byte School Student Information System (SIS) is intended to be the leading school operations SIS solution for educational institutions. Power your school operations with innovative, easy-to-use technology that plays a central role in education.',
	byteVoting: 'Byte Vote',
	byteVoteDesc:
		'Our Byte Voting system is intended to make online voting very seemless and easy with so many other features',
	// HR++
	customisesable: 'Customizable HR software',
	forYou: 'for you.',
	ourHRMS:
		'Our HR++ is easy to setup, understand, use and customize. Made up of different modules to make HR work seemless.',
	getStarted: 'Get Started',
	ourTrusted: 'Our TRUSTED HR++ Customers',
	// HR++ Core Features
	payrollManagement: 'PAYROLL MANAGEMENT',
	payrollMngtDesc:
		'Fortunately for accounting and HR departments, our HR++ can do most of the heavy lifting when it comes to payroll. From conveniently storing basic employee information to keeping you up to date with the most current tax laws, there isn’t much our HR++ can’t do for you. Our HR++ is able to automatically calculate wages and taxes and we customize these features to meet your specific payroll processes, allowing you to process payroll effortlessly. Our system can also help track employee costs, store and locate employee records, generate forms and send you reminders when it’s time to approve payroll.',
	empInfo: 'EMPLOYEE INFORMATION MANAGEMENT',
	empInfoDesc:
		'HR++ can maintain an updated directory of employees profile which include personal information, job and salary history, banking and tax details, insurance plans, leave requests, disciplinary history, performance feedback or any other custom fields important for your company. Upon request, HR++ can provide an employee self-service portal, allowing employees to log in and view important information, request leave, request loans, view payslips, perform top-bottom and/or bottom-top appraisal as well as a knowledge base of informative documents.',
	timeAttend: 'TIME AND ATTENDANCE MANAGEMENT',
	timeAttendMngtDesc:
		'The Time & Attendance module helps you organize and track employee hours and schedules; Prevent time theft and improve employee accountability; Track and monitor employee time, activities and productivity; Automate payroll and benefits processing and delivery; Comply with industry and government regulations etc. The T&A module can be seamlessly integrated with any biometric attendance deviceto improve attendance accuracy.',
	// HR++ MODULES
	payrollMngt: 'Payroll Management',
	payMngtDesc:
		"The payroll module in Byte HR++ handles all functionalities related to the management of an employee's payroll. These functionalities includes the ability to define unlimited pay grades, each having its own set of allowances and deductions; seemlessly integrated with the Time & Attendance module to process payroll using attendance records; ability to set and define pay components such as additions, deductions and tax tables; ability to calculate payroll daily, weekly, fortnightly or monthly for selected employee records, ability to process payroll for a single employee or a batch of employees; bank advice and payslip generation; standard payroll reports; access historical employee payroll reports and more.",
	performMngt: 'Appraisal / Performance Management',
	performMngtDesc:
		'Automating the appraisal process, recording and tracking objectives and targets, this module incorporate your competence framework, job standards and/or other relevant KPIs.',
	timeAttendMod: 'Time & Attendance Management',
	timeAttendModDesc:
		'The Attendance Module helps you track employee attendance accurately and provides on demand attendance reports on employee attendance at any given point in time. The module also give you the flexibility to specify work schedules for employees and set payroll rules accordingly.',
	leaveMngt: 'Absence & Leave Management',
	leaveMngtDesc:
		'Often linked to the time & attendance, and workforce management functions. The leave management module is an automated way to allocate, book, approve, track and monitor any absence from the workplace. It may be for vacations, compassionate reasons, illness etc. Request and approval processes are streamlined and the outcomes incorporated into team calendars where appropriate.',
	loanMngt: 'Loan Management',
	loanMngtDesc:
		'The Byte HR++ Loan module provide functionalities to configure different loan policies, assign approval authority, conduct the verification process and sanction loan for employees. It is integrated with the payroll management in such a way that loan re-payment are captured directly in the employees payslips. A standard report on employees loan balance can be printed at any given time ',
	recruitment: 'Recruitment',
	recruitmentDesc:
		'The recruitment module handles all your internal form-filling and authorization processes; allow managers or HR or both to post advertisements and supporting documentation online, offer applicant tracking and even initial sifting.',
	// SPONSOR DIALOG
	selectThe: "You've selected the",
	pleaseSendContact: 'Plan, please send us your contact details.',
	sponsorSuccess: 'DETAILS SENT',
	detailsSent:
		"Thank you for your interest, we've successfully received your details and will get back to you shortly.",
	sponsorFail: 'DETAILS NOT SENT',

	detailsFail: "Sorry, we didn't recive your details. Please try again...",
	cancel: 'Cancel',
	send: 'Send',
	ok: 'Ok',
	// CONTACT PAGE
	msgPlaceholder: 'Type your message here...',
	message: 'Message',
	email: 'Email Address',
	emailPlaceholder: 'Eg: johndoe@email.com',
	fullName: 'Full Name',
	namePlaceholder: 'Eg: John Doe',
	contactBS: 'Contact Byte Limited',
	contacts: 'Contacts',
	bytePhone: '+232 78 089-797',
	byteEmail: 'hello@byteltd.com',
	byteAddress: '1 Davies Drive, New England',
	ourOffice: 'Our Office',
	workingHours: 'Working Hours',
	messageSent: 'Message Sent!',
	thanksForContact:
		'Thank you for contacting Byte Limited, we will get back to you shortly.',
	messageNotSent: 'Message Not Sent',
	messageFail: "Sorry, we couldn't send your message. Please try again...",
	done: 'Done',
};

export default strings;
