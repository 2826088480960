import React from 'react';
import Hero from 'global/hero/ProductHero.js';
import Features from 'global/features/ByteProducts.js';
// import Blog from 'global/blogs/ThreeColSimpleWithImage.js';
import Testimonial from 'global/testimonials/OurClientsProduct.js';
// import ContactUsForm from 'global/forms/SimpleContactUs.js';

export default () => {
	return (
		<div>
			<Hero />
			<Features />
			{/* <Blog /> */}
			{/* <Testimonial /> */}
			
			{/* <ContactUsForm /> */}
		</div>
	);
};
