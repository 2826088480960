import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import HeaderBase, {
	LogoLink as LogoLinkBase,
	NavLinks,
	NavLink as NavLinkBase,
} from 'global/headers/light.js';
import logoImageSrc from 'global/images/logo192.png';
import strings from 'global/localization/en';

const Header = tw(
	HeaderBase,
)`max-w-none -mt-8 py-8 -mx-8 px-8 bg-primary-900 text-gray-100`;
const NavLink = tw(
	NavLinkBase,
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;

export default function NavBar({ modeSwitch }) {
	const logoLink = (
		<LogoLink href='/'>
			<img src={logoImageSrc} alt='Byte Logo' />
		</LogoLink>
	);
	const navLinks = [
		<NavLinks key={1}>
			<NavLink href='/'>{strings.home}</NavLink>
			<NavLink href='/products'>{strings.ourProducts}</NavLink>
			<NavLink href='/contact-us'>{strings.contactUs}</NavLink>
			<span>{modeSwitch}</span>
		</NavLinks>,
	];
	return <Header tw='fixed z-10 w-full' logoLink={logoLink} links={navLinks} />;
}
