import 'tailwindcss/dist/base.css';
import 'global/styles/globalStyles.css';
import React, { useState } from 'react';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Footer from 'global/footers/MiniCenteredFooter.js';
import AnimationRevealPage from 'global/helpers/AnimationRevealPage.js';
import Routes from 'pages/Routes';
import NavBar from 'global/headers/navbar/NavBar';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from 'global/styles/themes.js';
import { IconButton, Tooltip } from '@material-ui/core';
import { updateMode } from 'redux/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';

import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import AddressMap from 'global/maps/AddressMap';

const StyledApp = styled.div`
	color: ${(props) => props.theme.fontColor};
`;

function App() {
	const date = new Date();
	const dateAsString = date.toString();
	const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
	var clientTimezoneOffset = new Date().getTimezoneOffset() / 60; //offset in hours

	console.log('TIME ZONE', timezone);
	console.log('DATE', dateAsString);

	const theme = useSelector((state) => state.user.currentTheme);
	const dispatch = useDispatch();
	const saveMode = (data) => {
		dispatch(updateMode(data));
	};

	const themeToggler = () => {
		theme === 'light' ? saveMode('dark') : saveMode('light');
	};
	return (
		<AnimationRevealPage>
			<ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
				<GlobalStyles />
				<StyledApp>
					<NavBar
						modeSwitch={
							<button onClick={() => themeToggler()}>
								<Tooltip title='Toggle light/dark theme'>
									<IconButton>
										<WbIncandescentIcon fontSize='large' />
									</IconButton>
								</Tooltip>
							</button>
						}
					/>
					<Routes themeState={theme} tw='flex flex-wrap' />
					<AddressMap />
					<Footer />
				</StyledApp>
			</ThemeProvider>
		</AnimationRevealPage>
	);
}

export default App;
