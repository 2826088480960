import React, { useState, Fragment } from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import * as api from 'global/services/session/api';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	ListItem,
	List,
	TextField,
} from '@material-ui/core/';
import strings from 'global/localization/en';
import { makeStyles } from '@material-ui/core/styles';

import BackdropLoader from 'global/loaders/BackDropLoader';

// const ActionButton = styled(PrimaryButtonBase)`
// 	${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
// `;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;

const SecondaryLink = tw(
	Link,
)`text-gray-100 bg-red-500 border-gray-500 hover:bg-gray-100 hover:text-primary-500 hover:border-primary-500`;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

export default () => {
	const classes = useStyles();
	const [fullWidth] = useState(true);
	const [maxWidth] = useState('xs');

	const [open, setOpen] = useState(false);
	const [scroll, setScroll] = useState('paper');
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [contact_no, setContactNo] = useState('');
	const [message, setMessage] = useState('');
	const [validationMsg, setValidationMsg] = useState('');
	const [validErr, setValidErr] = useState(false);

	const handleClickOpen = (scrollType) => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleSuccess = () => {
		setOpen(false);
		setSuccess(false);
	};

	const handleFail = () => {
		setError(false);
	};

	const checkAllFields = () => {
		if (!name) {
			return ['Name is required', false];
		}
		if (!contact_no) {
			return ['Contact # is required', false];
		}
		if (!email) {
			return ['Email is required', false];
		}

		if (!verifyEmail(email)) {
			return ['Please enter a valid Email', false];
		}
		return ['Ok', true];
	};

	const verifyEmail = (email) => {
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return reg.test(email);
	};

	const handleClick = (e) => {
		e.preventDefault();
		if (checkAllFields()[0] === 'Ok') {
			setLoader(true);
			let payload = {
				source: 'Byte HR++ Pricing',
				name: name,
				contact_no: contact_no,
				email: email,
				message: message,
			};
			api
				.sendMessage(payload)
				.then((response) => {
					if (response.id) {
						setLoader(false);
						setSuccess(true);
						setEmail('');
						setMessage('');
						setName('');
					} else {
						setLoader(false);
						setError(true);
					}
				})
				.catch(() => {
					setLoader(false);
					setError(true);
				});
		} else {
			setValidErr(true);
			setValidationMsg(checkAllFields()[0]);
			setTimeout(() => {
				setValidErr(false);
				setValidationMsg('');
			}, 5000);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<>
			<SecondaryLink onClick={handleClickOpen('paper')}>
				{strings.contactUs}
			</SecondaryLink>

			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				{loader ? <BackdropLoader /> : null}
				{success ? (
					<Fragment
						open={true}
						fullWidth={fullWidth}
						maxWidth={maxWidth}
						aria-labelledby='scroll-dialog-title'
						aria-describedby='scroll-dialog-description'>
						<DialogTitle>{strings.messageSent}</DialogTitle>
						<DialogContent>
							<DialogContentText>{strings.thanksForContact}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								variant='contained'
								onClick={handleSuccess}
								color='primary'>
								{strings.done}
							</Button>
						</DialogActions>
					</Fragment>
				) : error ? (
					<Fragment
						open={true}
						fullWidth={fullWidth}
						maxWidth={maxWidth}
						aria-labelledby='scroll-dialog-title'
						aria-describedby='scroll-dialog-description'>
						<DialogTitle>{strings.messageNotSent}</DialogTitle>
						<DialogContent>
							<DialogContentText>{strings.messageFail}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant='contained' onClick={handleFail} color='primary'>
								{strings.ok}
							</Button>
						</DialogActions>
					</Fragment>
				) : (
					<Fragment>
						<DialogTitle id='scroll-dialog-title'>Hello</DialogTitle>
						<DialogContent dividers={scroll === 'paper'}>
							<DialogContentText
								id='scroll-dialog-description'
								ref={descriptionElementRef}
								tabIndex={-1}>
								<form>
									<List className={classes.root}>
										<ListItem>
											{validErr ? (
												<label>
													<p
														style={{
															color: '#ff0000bf',
															fontSize: '0.7rem',
															border: '1px solid',
															padding: '1rem',
															borderRadius: '10px',
														}}>
														{validationMsg}
													</p>
												</label>
											) : null}
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='name'
												label='Name'
												variant='outlined'
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='email'
												label='Email'
												variant='outlined'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												fullWidth
												id='contact_no'
												label='Contach No'
												variant='outlined'
												value={contact_no}
												onChange={(e) => setContactNo(e.target.value)}
											/>
										</ListItem>
										<ListItem>
											<TextField
												multiline
												rows={4}
												fullWidth
												id='message'
												label='Message'
												variant='outlined'
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											/>
										</ListItem>
									</List>
								</form>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color='primary' variant='contained'>
								{strings.cancel}
							</Button>
							<Button onClick={handleClick} color='primary' variant='contained'>
								{strings.send}
							</Button>
						</DialogActions>
					</Fragment>
				)}
			</Dialog>
		</>
	);
};
