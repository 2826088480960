import SimpleContactUs from 'global/forms/SimpleContactUs';
import WorkHours from 'global/forms/WorkingHours';
import ContactHero from 'global/hero/ContactHero';
import React from 'react';

export default function Contact({ themeState }) {
	return (
		<div>
			<ContactHero />
			<WorkHours themeState={themeState} />
			<SimpleContactUs />
		</div>
	);
}
