import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container as ContainerBase } from 'global/misc/Layouts.js';

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 -mb-8`;

const AddressMap = () => {
	return (
		<Container>
			<iframe
				tw='max-w-screen-xl mx-auto'
				src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15785.27254077219!2d-13.2424229!3d8.4684124!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9422ab4be3f299ba!2sByte%20Limited!5e0!3m2!1sen!2ssl!4v1606815308062!5m2!1sen!2ssl'
				width='100%'
				height='600'
				frameborder='0'
				style={{ border: 0 }}
				allowfullscreen=''
				aria-hidden='false'
				title='Map'
				tabindex='0'></iframe>
		</Container>
	);
};
export default AddressMap;
