import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ContentWithPaddingXl, Container } from 'global/misc/Layouts.js';
import { SectionHeading as Heading } from 'global/misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob1 } from 'global/images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'global/images/svg-decorator-blob-8.svg';
import Email from 'global/images/email.png';

const ContactInformation = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const ContactContainer = tw.div`mt-16 lg:w-1/3`;
const Contact = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const ContactInfo = tw.blockquote`mt-10 text-gray-600 font-medium leading-loose`;
const ContactInfo2 = tw.blockquote`-mt-16 mb-8 text-gray-600 font-medium leading-loose`;

const ContactName = tw.p`mt-5 text-gray-700 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
	${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
	${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
	themeState,
	heading = 'Contact Information',
	contactInformation = [
		{
			imageSrc:
				'https://i.pinimg.com/originals/6e/40/60/6e40605f0d1ca566c0e319871265dc87.png',
			info: '1 Davies Drive, New England',
			info2: 'Freetown, Sierra Leone',
			contactName: 'ADDRESS',
		},
		{
			imageSrc:
				'https://www.jonespubliclibrary.org/contact-information/phone.png/@@images/image.png',
			info: '+232 77-991-891',
			infoNum: ' / +232 75-209-189',
			contactName: 'PHONE',
		},
		{
			imageSrc: `${Email}`,
			info: 'contact@byteltd.com',
			contactName: 'E-MAIL',
		},
	],
}) => {
	return (
		<Container>
			<ContentWithPaddingXl>
				<Heading>{heading}</Heading>
				<ContactInformation>
					<ContactContainer>
						<Contact>
							<Image src='https://i.pinimg.com/originals/6e/40/60/6e40605f0d1ca566c0e319871265dc87.png' />
							<ContactInfo
								style={{
									color: themeState === 'dark' ? 'whitesmoke' : null,
								}}>
								1 Davies Drive, New England
							</ContactInfo>
							<ContactInfo2
								style={{
									color: themeState === 'dark' ? 'whitesmoke' : null,
								}}>
								Freetown, Sierra Leone
							</ContactInfo2>
							<ContactName>ADDRESS</ContactName>
						</Contact>
					</ContactContainer>
					<ContactContainer>
						<Contact>
							<a href='tel:+23277991891'>
								<Image src='https://www.jonespubliclibrary.org/contact-information/phone.png/@@images/image.png' />
							</a>
							<ContactInfo
								style={{
									color: themeState === 'dark' ? 'whitesmoke' : null,
								}}>
								<a href='tel:+23277991891'>+232 77-991-891</a> /{' '}
								<a href='tel:+23275209189'>+232 75-209-189</a>
							</ContactInfo>
							<ContactName>PHONE</ContactName>
						</Contact>
					</ContactContainer>
					<ContactContainer>
						<Contact>
							<a href='mailto:contact@byteltd.com'>
								<Image src={Email} />
							</a>
							<ContactInfo
								style={{
									color: themeState === 'dark' ? 'whitesmoke' : null,
								}}>
								<a href='mailto:contact@byteltd.com'>contact@byteltd.com </a>
							</ContactInfo>
							<ContactName>EMAIL</ContactName>
						</Contact>
					</ContactContainer>
				</ContactInformation>
			</ContentWithPaddingXl>
			<DecoratorBlob1 />
			<DecoratorBlob2 />
		</Container>
	);
};
