import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from 'global/images/dot-pattern.svg';
import strings from 'global/localization/en';
import * as api from 'global/services/session/api';
import BackdropLoader from 'global/loaders/BackDropLoader';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core/';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
	${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
	form {
		${tw`mt-4`}
	}
	h2 {
		${tw`text-3xl sm:text-4xl font-bold`}
	}
	input,
	textarea {
		${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

		::placeholder {
			${tw`text-gray-500`}
		}
	}
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
	SvgDotPatternIcon,
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default () => {
	const [fullWidth] = useState(true);
	const [maxWidth] = useState('xs');

	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [validationMsg, setValidationMsg] = useState('');
	const [validErr, setValidErr] = useState(false);

	const handleSuccess = () => {
		setSuccess(false);
	};

	const handleFail = () => {
		setError(false);
	};

	const checkAllFields = () => {
		if (!name) {
			return ['Name is required', false];
		}
		if (!message) {
			return ['Message is required', false];
		}
		if (!email) {
			return ['Email is required', false];
		}

		if (!verifyEmail(email)) {
			return ['Please enter a valid Email', false];
		}
		return ['Ok', true];
	};

	const verifyEmail = (email) => {
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return reg.test(email);
	};
	const handleClick = (e) => {
		e.preventDefault();
		if (checkAllFields()[0] === 'Ok') {
			setLoader(true);
			let payload = {
				user_id: 1,
				name: name,
				email: email,
				message: message,
			};
			api
				.sendMessage(payload)
				.then((response) => {
					if (response.id) {
						setLoader(false);
						setSuccess(true);
						setEmail('');
						setMessage('');
						setName('');
					} else {
						setLoader(false);
						setError(true);
					}
				})
				.catch(() => {
					setLoader(false);
					setError(true);
				});
		} else {
			setValidErr(true);
			setValidationMsg(checkAllFields()[0]);
			setTimeout(() => {
				setValidErr(false);
				setValidationMsg('');
			}, 5000);
		}
	};
	return (
		<Container>
			{loader ? <BackdropLoader /> : null}
			{success ? (
				<Dialog
					open={success}
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					aria-labelledby='scroll-dialog-title'
					aria-describedby='scroll-dialog-description'>
					<DialogTitle>{strings.messageSent}</DialogTitle>
					<DialogContent>
						<DialogContentText>{strings.thanksForContact}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant='contained' onClick={handleSuccess} color='primary'>
							{strings.done}
						</Button>
					</DialogActions>
				</Dialog>
			) : error ? (
				<Dialog
					open={error}
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					aria-labelledby='scroll-dialog-title'
					aria-describedby='scroll-dialog-description'>
					<DialogTitle>{strings.messageNotSent}</DialogTitle>
					<DialogContent>
						<DialogContentText>{strings.messageFail}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant='contained' onClick={handleFail} color='primary'>
							{strings.ok}
						</Button>
					</DialogActions>
				</Dialog>
			) : null}
			<Content>
				<FormContainer>
					<div tw='mx-auto max-w-4xl'>
						<h2>Send us a message</h2>
						<form action='#'>
							<TwoColumn>
								<Column>
									{validErr ? (
										<label>
											<p
												style={{
													color: '#ff0000bf',
													fontSize: '0.7rem',
													border: '1px solid',
													padding: '1rem',
													borderRadius: '10px',
												}}>
												{validationMsg}
											</p>
										</label>
									) : null}
									<InputContainer>
										<Label htmlFor='name-input'>{strings.fullName}</Label>
										<Input
											id='name'
											placeholder={strings.namePlaceholder}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</InputContainer>
									<InputContainer>
										<Label htmlFor='email-input'>{strings.email}</Label>
										<Input
											id='email'
											placeholder={strings.emailPlaceholder}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</InputContainer>
								</Column>
								<Column>
									<InputContainer tw='flex-1'>
										<Label htmlFor='name-input'>{strings.message}</Label>
										<TextArea
											id='message'
											placeholder={strings.msgPlaceholder}
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										/>
									</InputContainer>
								</Column>
							</TwoColumn>

							<SubmitButton type='submit' onClick={handleClick}>
								Submit
							</SubmitButton>
						</form>
					</div>
					<SvgDotPattern1 />
				</FormContainer>
			</Content>
		</Container>
	);
};
