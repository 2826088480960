import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'global/helpers/AnimationRevealPage.js';
import Hero from 'global/hero/TwoColumnWithInput.js';
import Features from 'global/features/HrmsModules.js';
import MainFeature from 'global/features/HrmsQuality.js';
import FeatureWithSteps from 'global/features/HrmsCoreFeatures.js';
import Pricing from 'global/pricing/ThreePlans.js';
import Testimonial from 'global/testimonials/TwoColumnWithImageAndRating.js';
import FAQ from 'global/faqs/SingleCol.js';
import GetStarted from 'global/cta/GetStarted';
import heroScreenshotImageSrc from 'global/images/hero-screenshot-1.png';
import macHeroScreenshotImageSrc from 'global/images/hero-screenshot-2.png';
import HrmsPricing from 'global/pricing/HrmsPricing';

export default () => {
	const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
	const HighlightedText = tw.span`text-primary-500`;

	return (
		<AnimationRevealPage>
			<Hero roundedHeaderButton={true} />
			<FeatureWithSteps
				subheading={<Subheading>FEATURES</Subheading>}
				heading={
					<>
						Our Core <HighlightedText>Features.</HighlightedText>
					</>
				}
				textOnLeft={false}
				imageSrc={macHeroScreenshotImageSrc}
				imageDecoratorBlob={true}
				decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
			/>
			<Features
				subheading={<Subheading>MODULES</Subheading>}
				heading={
					<>
						We have Amazing <HighlightedText>Modules.</HighlightedText>
					</>
				}
			/>
			<MainFeature
				subheading={<Subheading>Quality Work</Subheading>}
				imageSrc={heroScreenshotImageSrc}
				imageBorder={true}
				imageDecoratorBlob={true}
			/>

			<HrmsPricing />
			{/* 
			<Testimonial
				subheading={<Subheading>Testimonials</Subheading>}
				heading={
					<>
						Our Clients <HighlightedText>Love Us.</HighlightedText>
					</>
				}
				testimonials={[
					{
						stars: 5,
						profileImageSrc:
							'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
						heading: 'Amazing User Experience',
						quote:
							'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
						customerName: 'Charlotte Hale',
						customerTitle: 'Director, Delos Inc.',
					},
					{
						stars: 5,
						profileImageSrc:
							'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',
						heading: 'Love the Developer Experience and Design Principles !',
						quote:
							'Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
						customerName: 'Adam Cuppy',
						customerTitle: 'Founder, EventsNYC',
					},
				]}
			/>
			*/}
			<FAQ
				subheading={<Subheading>FAQS</Subheading>}
				heading={
					<>
						You have <HighlightedText>Questions ?</HighlightedText>
					</>
				}
				description='And we have got answers to all of them. Here are the answers to some common questions that our customers ask before they start using our Byte-HRMS.'
				faqs={[
					{
						question: 'What is exactly HRMS ?',
						answer:
							'HRMS stands for "Human Resource Management System". Byte HRMS is a simple HRMS tool designed to manage and access End to End HR and Payroll Management of a company. Byte HRMS helps you to streamline all aspects of the HR and payroll processing system. It has a user-friendly interface; modules are integrated and can be customized for varying business needs, covering all aspects of payroll requirement across industries.',
					},
					{
						question: 'Will this solution fit our current HR practices?',
						answer:
							'Yes. Byte HRMS is designed to build on your current HR practices and policies. The flexible configuration allows you to tailor the system to meet your current and future needs and practices.',
					},
					{
						question: 'Can I manage security roles and record access rights?',
						answer:
							'Yes. You can create new security roles and manage access rights using our access right tool. The system administrator generally does these tasks.',
					},
					{
						question:
							'Are upgrades included? How does the upgrade system works?',
						answer:
							'All module upgrades are included in the HR software subscription fees. Minor updates are completed outside of business hours with minimal interruption. Unless they are payroll connected applications, it will typically not require any client IT involvement. Release notes outlining the changes and new functions are provided with the release date annoucement.',
					},
					{
						question: 'Will you provide HRMS training to my HR team?',
						answer:
							'Yes, as a mandatory step in the implementation methodology, Byte Limited provides training to your employees. If a trained employee leaves you, and you hire a new person, we can even train your new employee and educate him how to use the system effectively for your business objectives. At Byte Limited we have also developed Training Videos where functionality and modules are explained in details, such videos are very well accepted and appreciated by our existing users.',
					},
					{
						question:
							'How Byte team supports the product after implementation?',
						answer:
							'It is helps to provide on-time, on target support to our all customers irrespective of where their branches or offices are located. Also our consultants can connect on your server on real time for any kind of support required or maintenance. We offer annual maintenance programs that include technical support for your HRMS system, or we can provide technical support on an individual incident basis. Byte HRMS technical support staff is available to assist you with questions throughout the day, either by telephone or electronically.',
					},
					{
						question: 'Can I try Byte-HRMS before making a decision?',
						answer:
							'Yes. Upon requesting a demo, one of our representatives will contact you to set up your account and guide you through the different modules.',
					},
					{
						question: 'How long does it take to implement Byte-HRMS?',
						answer:
							'Byte-HRMS has a fast track implementation process that typically takes from 2 to 6 calendar weeks. This depends on the modules purchased, customization required, and the number of employees being on-boarded.',
					},
					{
						question:
							'Do you have security precautions and/or password authentication features?',
						answer:
							'Yes. We never store passwords in plain text, they are all hashed and encrypted. Any data transmission that happens between our users and our servers is encrypted as well, so no third party can take a peek at the data as it travels back and forth. Our cloud servers are hosted in state of the art data centers and adhere to strict security policies, which includes digital and physical access.',
					},
					{
						question:
							'Is Byte-HRMS localized and complaint with all payroll and labor law in my country?',
						answer:
							'Yes. The localization of Byte-HRMS is on a per-country basis and is localized for all countries in Sub-Saharan Africa.',
					},
				]}
			/>
			<GetStarted />
		</AnimationRevealPage>
	);
};
